function assignDefaultPermissions(permissions) {

    if (typeof permissions.cms === "undefined") {
        permissions.cms = false;
    }

    if (typeof permissions.dispatch === "undefined") {
        permissions.dispatch = false;
    }

    if (typeof permissions.transport === "undefined") {
        permissions.transport = false;
    }

    if (typeof permissions.maintenance === "undefined") {
        permissions.maintenance = false;
    }

    return permissions;

}


export const CompanyUtil = {
    assignDefaultPermissions
}
