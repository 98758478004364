import axios from 'axios';
import config from '@/config/env-constants';

export default {
  baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

  newCompany(companyId, currUserId) {
    const url = `${this.baseUrl}/newCompany`;
    return axios.post(url, { companyId, currUserId });
  }

}