<template>
	<b-modal id="add-company" size="lg" title="Add Company" ref="modal" ok-title="Add" @ok="handleOk" @show="onReset"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs content-class="mt-3">
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col sm="8">
									<b>PRIMARY INFORMATION</b>
								</b-col>
							</b-row>
							<b-row class="my-3">
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Name" label-for="name"
										description="Please enter the non-abbreviated name for the company">
										<b-form-input id="name" name="Name" type="text" v-model="form.name" v-validate="{
											required: true,
											regex: /^[ÑA-Za-z0-9][ñA-Za-z0-9\s&\'\-,.\/]{1,100}$/,
										}" placeholder="Name" />
										<span v-show="errors.has('Name')" class="help-block">
											{{ errors.first('Name') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Description" label-for="description" description="">
										<b-form-input id="description" name="Description" type="text"
											v-model="form.description" v-validate="{
												required: false,
												regex: /^[ÑA-Za-z0-9][ñA-Za-z0-9\s&\'\-,.\/]{1,200}$/,
											}" placeholder="Description" />
										<span v-show="errors.has('Description')" class="help-block">
											{{ errors.first('Description') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="=12">
									<b-form-group label="Type" label-for="type">
										<b-form-select id="type" name="Type" v-model="form.type" v-validate="{
											required: companyDetailIsRequired,
										}" :options="companyTypes" />
										<span v-show="errors.has('Type')" class="help-block">
											{{ errors.first('Type') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="=12">
									<b-form-group label="Industry" label-for="">
										<v-select id="industry" name="Industry" class="style-chooser" label="text"
											:options="industryOptions" :reduce="(industry) => industry.value"
											v-model="form.industry" v-validate="{
												required: companyDetailIsRequired,
											}">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a industry
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Industry')" class="help-block">
											{{ errors.first('Industry') }}
										</span>
									</b-form-group>
									<b-form-group label="" label-for="" description="* Please specify here"
										v-show="industryOthersIsHidden">
										<b-form-input id="industryOthers" name="Industry Others"
											v-model="form.industryOthers" v-validate="{
												required: companyDetailIsRequired,
												regex: /^([a-zA-Z0-9&\v, \-])*$/,
											}" :disabled="industryOthersIsDisabled" />
										<span v-show="errors.has('Industry Others')" class="help-block">
											{{ errors.first('Industry Others') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-3">
								<b-col lg="6" md="6" sm="=12">
									<b-form-group label="Has Parent Company?">
										<b-form-radio-group name="Has Parent Company" v-model="form.hasParentCompany"
											v-validate="'required'" :options="boolOptions" plain
											:disabled="hasParentCompanyIsDisabled" />
										<span v-show="errors.has('Has Parent Company')" class="help-block">
											{{ errors.first('Has Parent Company') }}
										</span>
									</b-form-group>
									<b-form-group label="" label-for=""
										description="* Please select the parent company here" v-if="form.hasParentCompany">
										<v-select name="Parent Company" class="style-chooser" label="text"
											:options="companyOptions" :reduce="(company) => company.value"
											v-model="selCompany" v-validate="'selectRequired'"
											:disabled="hasParentCompanyIsDisabled">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Parent Company')" class="help-block">
											{{ errors.first('Parent Company') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Address">
							<b-row class="my-2">
								<b-col sm="8">
									<b>ADDRESS</b>
								</b-col>
							</b-row>
							<b-row class="my-3">
								<b-col lg="12" md="12" sm="12">
									<b-form-group label="Main Office Address" label-for="address"
										description="* Please enter the complete company's main office address">
										<b-form-input id="address" name="Address" type="text" v-model="form.address"
											v-validate="{
												required: true,
												regex: /^(?=.*[A-Za-z])[A-Za-z\d\s(),.]+$/,
											}" required placeholder="Address" />
										<span v-show="errors.has('Address')" class="help-block">
											{{ errors.first('Address') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="my-4">
								<b-col sm="12">
									<b-form-group label="Geographical Location" label-for="geoaddress"
										description="* This is the exact coordinates that will be used to locate in the map">
										<b-row class="my-2">
											<b-col lg="4" md="6" sm="12">
												<b-form-group description="Latitude">
													<b-form-input id="latitude" name="Latitude" type="number"
														v-model="form.geoaddress.latitude"
														v-validate="'required|min_value:-90|max_value:90'" min="-90"
														max="90" step="0.01" onwheel="this.blur()" placeholder="Latitude" />
												</b-form-group>
												<span v-show="errors.has('Latitude')" class="help-block">
													{{ errors.first('Latitude') }}
												</span>
											</b-col>
											<b-col lg="4" md="6" sm="12">
												<b-form-group description="Longitude">
													<b-form-input id="longitude" name="Longitude" type="number"
														v-model="form.geoaddress.longitude"
														v-validate="'required|min_value:-180|max_value:180'" min="-180"
														max="180" step="0.01" onwheel="this.blur()"
														placeholder="Longitude" />
												</b-form-group>
												<span v-show="errors.has('Longitude')" class="help-block">
													{{ errors.first('Longitude') }}
												</span>
											</b-col>
											<b-col lg="4" md="6" sm="12">
												<b-link href="https://www.latlong.net/" target="_blank">
													<i class="icon-info"></i>&nbsp;Generate Coordinates
													Here
												</b-link>
											</b-col>
										</b-row>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Contact Details">
							<b-row class="my-2">
								<b-col sm="8">
									<b>CONTACT DETAILS</b>
								</b-col>
							</b-row>
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Contact Person" label-for="contactPerson" description>
										<b-form-input id="contactPerson" name="Contact Person" type="text"
											v-model="form.contactPerson" v-validate="{
												required: false,
												regex: /^[a-zA-Z.\s]*$/,
											}" placeholder="Name" />
										<span v-show="errors.has('Contact Person')" class="help-block">
											{{ errors.first('Contact Person') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Email" label-for="emailAddress" description>
										<b-form-input id="email" name="Email" type="email" v-model="form.emailAddress"
											v-validate="'email'" placeholder="Email" />
										<span v-show="errors.has('Email')" class="help-block">
											{{ errors.first('Email') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Contact No" label-for="contactNo" description>
										<b-form-input id="contactNo" name="Contact No" v-model="form.contactNo"
											onwheel="this.blur()" v-validate="{
												required: false,
												regex:
													/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{1,3})[-. )]*(\d{1,4})[-. ]*(\d{4})(?: *x(\d+))?\s*([\/0-9-()]*)?$/,
											}" placeholder="Contact No" />
										<span v-show="errors.has('Contact No')" class="help-block">
											{{ errors.first('Contact No') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Subscriptions">
							<b-row class="my-2">
								<b-col sm="8">
									<b>SUBSCRIPTION DETAILS</b>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Number of Storage Locations" label-for="noOfStorageLocations"
										description>
										<b-form-input id="noOfStorageLocations" name="No of Storage Location" type="number"
											onwheel="this.blur()" v-model="form.noOfStorageLocations" v-validate="{
												required: true,
												max_value: 10000,
												min_value: 0,
											}" placeholder />
										<span v-show="errors.has('No of Storage Location')" class="help-block">
											{{ errors.first('No of Storage Location') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Number of Inactive Nodes" label-for="noOfInactiveNodes"
										description>
										<b-form-input id="noOfInactiveNodes" name="No of Inactive Nodes" type="number"
											onwheel="this.blur()" v-model="form.noOfInactiveNodes" v-validate="{
												required: companyDetailIsRequired,
												max_value: 10000,
												min_value: 0,
											}" placeholder :disabled="companyDetailIsDisabled" />
										<span v-show="errors.has('No of Inactive Nodes')" class="help-block">
											{{ errors.first('No of Inactive Nodes') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Number of Users" label-for="noOfUsers" description>
										<b-form-input id="noOfUsers" name="No of Users" type="number" onwheel="this.blur()"
											v-model="form.noOfUsers" v-validate="{
												required: companyDetailIsRequired,
												max_value: 10000,
												min_value: 0,
											}" placeholder :disabled="companyDetailIsDisabled" />
										<span v-show="errors.has('No of Users')" class="help-block">
											{{ errors.first('No of Users') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Asset Brand Name" label-for="brand"
										description="This will be used as the brand naming of the company asset types">
										<b-form-input id="brand" name="Brand" type="text" v-model="form.brand" v-validate="{
											required: companyDetailIsRequired,
											regex: /^([ÑñA-Za-z0-9]+)$/,
										}" placeholder :disabled="companyDetailIsDisabled" />
										<span v-show="errors.has('Brand')" class="help-block">
											{{ errors.first('Brand') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Branch Code" label-for="branchCode"
										description="Client-specific field to mark a company as one of their branches">
										<b-form-input id="branchCode" name="Branch Code" type="text"
											v-model="form.branchCode" v-validate="{
												required: false,
												regex: /^[ÑA-Za-z0-9][ñA-Za-z0-9\s&\'\-,.\/]{1,100}$/,
											}" placeholder="Branch Code" />
										<span v-show="errors.has('Branch Code')" class="help-block">
											{{ errors.first('Branch Code') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Status">
										<b-form-radio-group v-model="form.isActive" :options="statusOptions"
											name="Status" />
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Company Logo" label-for="company-logo"
										description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
										<b-form-file id="company-logo" placeholder="Choose image" ref="companyLogofileInput"
											accept="image/png" @change="onSelectCompanyLogo($event)">
											<template slot="file-name" slot-scope="{ names }">
												<b-badge variant="dark">{{ names[0] }}</b-badge>
												<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
													names.length - 1 }} More files</b-badge>
											</template>
										</b-form-file>
									</b-form-group>

									<!-- Image Preview -->
									<div v-if="companyLogo.url">
										<b-card overlay :img-src="companyLogo.url" img-alt="Card Image" text-variant="white"
											class="mb-0">
											<b-card-text class="text-right">
												<b-button variant="danger" size="sm" @click="onRemoveCompanyLogo()">
													<em class="fa fa-trash"></em>
												</b-button>
											</b-card-text>
										</b-card>
										<b-progress v-if="uploading.uploadStatus === 'uploading'"
											:value="uploading.percentage" :max="uploading.filename" variant="success"
											height="8px" animated />
									</div>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Permissions" v-if="loggedUser.type === 'Super Admin'">
							<b-row class="my-2">
								<b-col sm="8">
									<b>PERMISSIONS</b>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Content Management System">
										<b-form-radio-group name="Content Management System" v-model="form.permissions.cms"
											v-validate="'required'" :options="boolOptions" plain />
										<span v-show="errors.has('Content Management System')" class="help-block">
											{{ errors.first('Content Management System') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Dispatch">
										<b-form-radio-group name="Dispatch" v-model="form.permissions.dispatch"
											v-validate="'required'" :options="boolOptions" plain />
										<span v-show="errors.has('Dispatch')" class="help-block">
											{{ errors.first('Dispatch') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Transport">
										<b-form-radio-group name="Transport" v-model="form.permissions.transport"
											v-validate="'required'" :options="boolOptions" plain />
										<span v-show="errors.has('Transport')" class="help-block">
											{{ errors.first('Transport') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Maintenance">
										<b-form-radio-group name="Maintenance" v-model="form.permissions.maintenance"
											v-validate="'required'" :options="boolOptions" plain />
										<span v-show="errors.has('Maintenance')" class="help-block">
											{{ errors.first('Maintenance') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { CompanyUtil } from '@/utils/companyUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { FileUtil } from '@/utils/fileUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import companyApi from '@/api/companyApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import firebase from 'firebase/app';
import { storage } from '@/config/firebase';
import _ from 'lodash';

export default {
	name: 'add-company',
	components: {
		Loading,
	},
	props: {
		allIndustriesObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: {
				name: '',
				description: '',
				type: null,
				industry: null,
				industryOthers: null,
				hasParentCompany: false,
				parentCompany: '',
				parentCompanyId: '',
				isActive: 'true',

				address: '',
				geoaddress: {
					latitude: null,
					longitude: null,
				},

				contactPerson: '',
				contactNo: '',
				emailAddress: '',

				// subscriptions details
				noOfStorageLocations: 0,
				noOfInactiveNodes: 0,
				noOfUsers: 0,
				brand: '',
				branchCode: '',

				permissions: Object.assign({}, config.permissionsDefaultValues),
				companyLogo: {},

				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},
			companyTypes: config.companyTypes,
			industryOptions: [],
			statusOptions: [
				{ text: 'Active', value: 'true' },
				{ text: 'Inactive', value: 'false' },
			],
			boolOptions: [
				{ text: 'YES', value: true },
				{ text: 'NO', value: false },
			],
			companyOptions: [],

			selCompany: Object.assign({}, config.companyDefaultValue),
			allCompaniesObj: {},

			companyLogo: {},
			uploading: {
				filename: '',
				percentage: 0,
				uploadStatus: '', // [uploading, success, error]
			},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		companyDetailIsRequired() {
			return this.form.isActive === 'true';
		},
		companyDetailIsDisabled() {
			return this.form.isActive !== 'true';
		},
		hasParentCompanyIsDisabled() {
			return !this.isSuperAdmin;
		},
		industryOthersIsHidden() {
			return this.form.industry === 'Others';
		},
		industryOthersIsDisabled() {
			return this.form.industry !== 'Others';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.form.name;
		},
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			this.cleanupFormFields();
			this.updateParentCompanySelection();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Check if existing company
			if (
				ValidationUtil.objectHasField(
					'name',
					this.form.name,
					this.allCompaniesObj
				)
			) {
				this.$toaster.warning('Company name already exists.');
				// hide loading indicator
				this.isLoading = false;
				return;
			} else if (this.form.name == this.form.parentCompany) {
				this.$toaster.warning(
					'Invalid parent company. Company must not have the same name of its parent company.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},

		cleanupFormFields() {
			// Removes excess whitespace
			this.form.name = ValidationUtil.removeExcessWhiteSpace(this.form.name);
			this.form.industryOthers = ValidationUtil.removeExcessWhiteSpace(
				this.form.industryOthers
			);
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);
			this.form.address = ValidationUtil.removeExcessWhiteSpace(
				this.form.address
			);
			this.form.contactPerson = ValidationUtil.removeExcessWhiteSpace(
				this.form.contactPerson
			);

			if (this.form.industry === 'Others') {
				this.form.industry = this.form.industryOthers;
			}
		},
		updateParentCompanySelection() {
			if (this.selCompany.id && this.form.hasParentCompany) {
				this.form.parentCompany = this.selCompany.name;
				this.form.parentCompanyId = this.selCompany.id;
			} else {
				this.form.parentCompany = '';
				this.form.parentCompanyId = '';
			}
		},

		getCompanyObj(param) {
			// pre-process geoaddress
			if (!_.isEmpty(param.geoaddress)) {
				let latitude = LocationUtil.getLatitude(param.geoaddress);
				let longitude = LocationUtil.getLongitude(param.geoaddress);
				param.geoaddress = new firebase.firestore.GeoPoint(
					parseFloat(latitude),
					parseFloat(longitude)
				);
			}

			return {
				name: param.name,
				description: param.description,
				type: param.type,
				industry: param.industry,
				hasParentCompany: param.hasParentCompany,
				parentCompany: param.parentCompany,
				parentCompanyId: param.parentCompanyId,
				isActive: param.isActive,
				address: param.address,
				geoaddress: param.geoaddress,
				contactPerson: param.contactPerson,
				contactNo: param.contactNo,
				emailAddress: param.emailAddress,
				brand: param.brand,
				branchCode: param.branchCode,
				noOfStorageLocations: param.noOfStorageLocations
					? parseInt(param.noOfStorageLocations)
					: 0,
				noOfInactiveNodes: param.noOfInactiveNodes
					? parseInt(param.noOfInactiveNodes)
					: 0,
				noOfUsers: param.noOfUsers ? parseInt(param.noOfUsers) : 0,
				permissions: param.permissions,
				companyLogo: param.companyLogo,
				dateCreated: DateUtil.getCurrentTimestamp(),
				createdBy: this.loggedUser.id,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let companyObj = this.getCompanyObj(this.form);
				let { data } = await companyApi.saveCompany(
					companyObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					// upload company logo
					if (!_.isEmpty(this.companyLogo)) {
						data.company = await this.uploadCompanyLogo(data.company);
					} else {
						data.company.companyLogo = {};
					}

					this.$toaster.success(
						`Company "${this.name}" was created successfully.`
					);
					EventBus.$emit('onCloseAddCompany', data);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error creating company "${this.name}". Please try again.`
					);
				}

				// hide loading indicator
				this.isLoading = false;
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.error(
					`Error creating company "${this.name}". Please try again.`
				);
			}
		},

		async uploadCompanyLogo(company) {
			let image = this.companyLogo;
			if (image._isNew) {
				const uploadResult = await this.firebaseUploadImage(image, company);
				if (uploadResult.name && uploadResult.url) {
					let logoDetails = {
						id: company.id,
						logo: uploadResult,
					};

					let { data } = await companyApi.updateCompanyLogo(
						logoDetails,
						this.loggedUser.id,
						DateUtil.getCurrentTimestamp()
					);

					if (data.isSuccess) {
						company = data.company;
						this.uploadingInProgress('success');
					}
				}
			}

			return company;
		},

		async firebaseUploadImage(image, company) {
			let filename = `COMPANYLOGO_${company.name}.png`;

			this.uploadingInProgress('uploading', filename, 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${image.fbStoragePath}/${filename}`);
				let task = storageRef.put(image.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.uploadingInProgress('uploading', filename, percentage);
					},
					(error) => {
						reject(error);
						this.resetUploadingState();
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: filename,
								url: downloadURL,
							});
						});
					}
				);
			});
		},

		uploadingInProgress(uploadStatus, filename = '', percentage = 0) {
			this.uploading.filename = filename;
			this.uploading.percentage = percentage;
			this.uploading.uploadStatus = uploadStatus;
		},

		resetUploadingState() {
			this.uploading = {
				filename: '',
				percentage: 0,
				uploadStatus: '',
			};
		},

		onRemoveCompanyLogo(index) {
			this.companyLogo = {};
			this.$refs['companyLogofileInput'].reset();
		},

		onSelectCompanyLogo(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid company logo in PNG or JPEG format.'
				);
				this.companyLogo = {};
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"Company logo's width and height shouldn't be greater than 1000 pixels"
					);
				} else {
					vm.companyLogo = {
						url: url,
						file: file,
						fbStoragePath: 'images/companyLogos',
						_isNew: true,
					};
				}
			};
			image.src = url;
		},

		onReset() {
			/* Reset our form values */
			this.form.name = '';
			this.form.description = '';
			this.form.type = null;
			this.form.industry = null;
			this.form.industryOthers = null;

			this.form.isActive = 'true';

			this.form.address = '';
			this.form.geoaddress = {
				latitude: null,
				longitude: null,
			};

			this.form.contactPerson = '';
			this.form.contactNo = '';
			this.form.emailAddress = '';

			this.form.noOfStorageLocations = '';
			this.form.noOfInactiveNodes = '';
			this.form.noOfUsers = '';
			this.form.brand = '';
			this.form.branchCode = '';

			if (!this.isSuperAdmin) {
				this.form.hasParentCompany = true;
				this.form.parentCompany = this.loggedUserCompany.name;
				this.form.parentCompanyId = this.loggedUserCompany.id;
				this.selCompany = DropDownItemsUtil.getCompanyItem(
					this.loggedUserCompany
				);

				this.form.permissions = Object.assign(
					{},
					config.permissionsDefaultValues
				);
			} else {
				this.form.hasParentCompany = false;
				this.form.parentCompany = '';
				this.form.parentCompanyId = '';
				this.selCompany = Object.assign({}, config.companyDefaultValue);
				this.form.permissions = CompanyUtil.assignDefaultPermissions(this.loggedUserCompany.permissions);
			}

			this.form.companyLogo = {};
			this.companyLogo = {};

			this.resetUploadingState();
			this.resetDropdownValues();

			// reset validation
			this.isLoading = false;
			this.$validator.reset();
			this.errors.clear();
		},
		resetDropdownValues() {
			this.allCompaniesObj = this.$store.getters.companies;
			this.companyOptions = DropDownItemsUtil.retrieveCompanyItems(
				this.allCompaniesObj, this.loggedUserCompany
			);

			this.industryOptions = DropDownItemsUtil.retrieveIndustryItems(
				this.allIndustriesObj,
				true
			);
		},
	},
};
</script>

<style scoped></style>
